import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"main_wide"},[_c('div',{staticClass:"header header_constructor"},[_c(VRow,[_c(VCol,[_c('h1',{staticClass:"heading"},[_vm._v("Mails")])])],1)],1),_c('div',{staticClass:"header header_constructor"},[_c(VRow,[_c(VCol,{attrs:{"cols":"4"}},[_c(VTextField,{attrs:{"append-icon":"mdi-plus","outlined":"","solo":"","dense":""},on:{"click:append":_vm.addMail,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addMail.apply(null, arguments)}},model:{value:(_vm.mail),callback:function ($$v) {_vm.mail=$$v},expression:"mail"}})],1)],1)],1),_c('div',{staticClass:"header header_constructor"},[_c(VRow,[_c(VCol,[_c(VDataTable,{staticClass:"table rooms__table",attrs:{"headers":_vm.headers,"no-data-text":"No Events","items":_vm.mails,"hide-default-footer":"","items-per-page":999},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item,i){return _c('tr',{key:i},[_c('td',{staticClass:"d-block d-sm-table-cell"},[_c(VLayout,{attrs:{"justify-center":""}},[_vm._v(" "+_vm._s(item.mail)+" ")])],1),_c('td',{staticClass:"d-block d-sm-table-cell"},[_c(VLayout,{attrs:{"justify-center":""}},[_c(VIcon,{staticStyle:{"margin-right":"5px"},attrs:{"color":"#007db5"}},[_vm._v("mdi-pencil")]),_c(VIcon,{attrs:{"color":"#7F8385"}},[_vm._v("mdi-delete")])],1)],1)])}),0)]}}])})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }