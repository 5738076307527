<template>
  <main class="main_wide">
    <div class="header header_constructor">
      <v-row>
        <v-col>
    <h1 class="heading">Mails</h1>
        </v-col>
      </v-row>
    </div>
    <div class="header header_constructor">
      <v-row>
        <v-col cols="4">
          <v-text-field
              append-icon="mdi-plus"
              outlined
              solo
              @click:append="addMail"
              v-on:keydown.enter="addMail"
              v-model="mail"
              dense
          ></v-text-field>
        </v-col>
      </v-row>
    </div>

    <div class="header header_constructor">
      <v-row>
        <v-col >
          <v-data-table
              class="table rooms__table"
              :headers="headers"
              no-data-text="No Events"
              :items="mails"
              hide-default-footer
              :items-per-page="999"
          >
            <template v-slot:body="{ items }">
              <tbody >
              <tr v-for="(item, i) in items" :key="i">
                <td class="d-block d-sm-table-cell">
                  <v-layout justify-center>
                    {{ item.mail }}
                  </v-layout>
                </td>
                <td class="d-block d-sm-table-cell">
                  <v-layout justify-center>
                    <v-icon color="#007db5" style="margin-right: 5px;">mdi-pencil</v-icon>
                    <v-icon color="#7F8385">mdi-delete</v-icon>
                  </v-layout>
                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </main>
</template>

<script>
export default {
  name: "Mails",
  data(){
    return{
      mail:"",
      mails:[],
      headers: [
        { text: 'Mail', value: "mail", align: 'center',sortable:true, },
        { text: 'Options', align: 'center',sortable:false, },
      ],
    }
  },
  methods:{
    addMail(){
      let mail = {
        mail:"",
        edit:""
      }
      mail.mail = this.mail;
      this.mails.push(mail)
      this.mail = "";
    }
  }
}
</script>

<style scoped lang="scss">

.header_constructor {
  padding: 30px 30px;
  .heading {
    margin-bottom: 20px;
  }
}
</style>